@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;500;600;700;800&display=swap");
.button {
  background: black;
  border-radius: 7px;

  color: white;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(180deg, #05050538 0%, #0f0f0f 15%);
}
.button1 {
  margin-bottom: 2%;

  color: #c4c4c4;
  font-size: 16px;
  font-weight: bold;
  background-color: transparent;
  border-radius: 7px;
}
.button2 {
  background: black;
  border-radius: 7px;

  color: white;
  font-size: 14px;
  font-weight: bold;
  background: linear-gradient(180deg, #05050538 0%, #0f0f0f 15%);
}
.buttonStyle {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  min-width: 200px;
  min-height: 50px;
  border: none;
  border-radius: 30px;
  padding: 17px 20px;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;

  color: white;
}

.buttonStyleFileUpload {
  font-family: "Open Sans", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.35);
  min-width: 240px;
  min-height: 20px;
  border: none;
  border-radius: 30px;
  padding: 10px 50px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;

  color: white;
}

.button3 {
  margin-bottom: 2%;

  color: #c4c4c4;
  font-size: 10px;
  font-weight: bold;
  border: 1px solid #c4c4c4;
  background-color: transparent;
  border-radius: 7px;
}

.buttonIns {
  border-radius: 7px;
  border-color: black;

  color: white;
  font-size: 18px;
  font-weight: bold;
  background: linear-gradient(180deg, #05050500 0%, #0f0f0f75 15%);
}
