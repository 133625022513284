.txt-label {
  color: white;
  font-weight: 600;
  text-align: start;
}

.txt-label-bigger {
  color: white;
  font-weight: 600;
  text-align: center;
  font-size: 20px;
}

.txt-welcomeMessage {
  margin-left: 10vw;
  margin-right: 10vw;
}

.txt-welcome {
  color: white;
  font-weight: 600;
  font-size: 12vw;
}

.button-custom {
  .button {
    padding: 100px;
  }
}

.checkbox-style {
  color: white !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: bold !important;
}

.form-style {
  color: white !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: bold !important;
}

.select-stuff {
  border-color: white !important;
  color: white;
  fill: white;
}

MuiSelect-select {
  border-color: white;
}

.MuiInputBase-input {
  color: wheat;
}
MuiSelect-icon {
  fill: white;
  color: white;
}
