.fieldset-class-field-dark {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  height: 30px;
}

.fieldset-class-field:focus-within {
  height: 40px;
}

.fieldset-label-class-field-dark .fieldset-input-class-field-dark {
  background: transparent;
  padding: 5px;
  outline: none;
  color: white;
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fieldset-label-class-field-dark {
  color: white;
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  width: max-content;
  transform: translate(-50%, -50%);
  transition: 0.1s ease-in all;
}

.fieldset-input-class-field-dark {
  font-family: "Open Sans", sans-serif;
  outline: none;
  background: transparent;
  width: 100%;
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 14px;
  font-weight: 600;
  transform: translate(-50%, -60%);
}

.fieldset-input-class-field-dark:focus {
  outline: none;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  background: transparent;
  width: 100%;
  color: white;
  transform: translate(-50%, -60%);
  font-size: 14px;
  font-weight: 600;
}

.fieldset-input-class-field-dark:not(:placeholder-shown) {
  outline: none;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  background: transparent;
  width: 100%;
  color: white;
  transform: translate(-50%, -60%);
  font-size: 14px;
  font-weight: 600;
}

.fieldset-input-class-field-dark:focus ~ .fieldset-class-field-dark {
  height: 50px;
}

.fieldset-input-class-field-dark:focus ~ .fieldset-label-class-field-dark {
  position: initial;
  font-size: small;
  color: white;
  margin-left: 5px;
  transform: translate(0%, 0%) scale(1);
  width: fit-content;
}

.fieldset-input-class-field-dark:not(:placeholder-shown)
  ~ .fieldset-label-class-field-dark {
  position: initial;
  font-size: small;
  color: white;
  margin-left: 5px;
  transform: translate(0%, 0%) scale(1);
  width: fit-content;
}

.fieldset-class-field {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  height: 30px;
}

.fieldset-label-class-field .fieldset-input-class-field {
  background: transparent;
  padding: 5px 5px 5px 5px;
  outline: none;
  color: black;
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
}

.fieldset-label-class-field {
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  pointer-events: none;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  width: max-content;
  transform: translate(-50%, -50%);
  transition: 0.1s ease-in all;
}

.fieldset-input-class-field {
  font-family: "Open Sans", sans-serif;
  outline: none;
  background: transparent;
  width: 100%;
  color: black;
  text-align: center;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  font-size: 14px;
  font-weight: 600;
  transform: translate(-50%, -60%);
}

.fieldset-input-class-field:focus {
  outline: none;
  text-align: center;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  background: transparent;
  width: 100%;
  color: black;
  transform: translate(-50%, -60%);
  font-size: 14px;
  font-weight: 600;
}

.fieldset-input-class-field:not(:placeholder-shown) {
  outline: none;
  text-align: center;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  background: transparent;
  width: 100%;
  color: black;
  transform: translate(-50%, -60%);
  font-size: 14px;
  font-weight: 600;
}

.fieldset-input-class-field:focus ~ .fieldset-class-field {
  height: 50px;
}

.fieldset-input-class-field:focus ~ .fieldset-label-class-field {
  position: initial;
  font-size: small;
  color: black;
  margin-left: 5px;
  transform: translate(0%, 0%) scale(1);
  width: fit-content;
}

.fieldset-input-class-field:not(:placeholder-shown)
  ~ .fieldset-label-class-field {
  position: initial;
  font-size: small;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
  transform: translate(0%, 0%) scale(1);
  width: fit-content;
}

.input-simple-dark {
  min-height: 38px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-color: rgba(255, 255, 255, 0.5);
  background: transparent;
  text-align: center;
  color: white;
}

// make the input-simple-dark with hover and focus
.input-simple-dark:hover {
  border-color: white;
}

.input-simple-dark:focus {
  border-color: white;
}

.input-simple {
  min-height: 38px;
  width: 100%;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-color: rgba(0, 0, 0, 0.5);
  background: transparent;
  text-align: center;
  color: black;
}

// make the input-simple-dark with hover and focus
.input-simple:hover {
  border-color: black;
}

.input-simple:focus {
  border-color: black;
}
