.screen1 {
  #video-stream {
    video {
      height: 65vh;
    }
  }
  img {
    margin-top: 4em;
    margin-bottom: 4em;
  }
  .box_one {
    .title1 {
      font-weight: bold;
      font-size: 24px;
      line-height: 20px;

      text-align: center;

      color: #ffffff;
    }
  }
  .title2 {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;

    color: #ffffff;
  }
}
