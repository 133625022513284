.container {
  padding: 8% 12% 0% 12%;
  color: white;
  .box {
    display: flex;
    justify-content: right;
    .square {
      color: white;

      // width: 80%;
      padding: 2%;
      fieldset {
        text-align: left;
        border: 1px solid #ffffff21;
        border-radius: 15px;
        text-align: -webkit-center;
      }

      fieldset legend {
        font-weight: 900;
        font-size: 18px;
        color: #fff;
        padding: 5px 10px;
      }
      .text {
        width: 156px;
        height: 23px;
        color: #fff;
        margin-top: 5%;
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: bold;
        font-size: 24px;
        line-height: 8px;
        /* or 117% */

        text-align: center;

        color: rgba(255, 255, 255, 0.5);
      }
    }
  }

  .txt-iva {
    text-align: right;
    font-size: 18px;
    line-height: 4px;
    color: rgba(255, 255, 255, 0.5);
  }
  .txt-price {
    text-align: right;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 4px;

    color: #ffffff;
  }
  .txt-text {
    p {
      font-size: 12px;
      width: 282px;

      color: rgba(255, 255, 255, 0.65);
      text-align: -webkit-left;
    }
  }
  .txt-last {
    display: flex;
    justify-content: center;
    p {
      margin-left: 6%;
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 2px;

      color: rgba(255, 255, 255, 0.65);
    }
  }
}
