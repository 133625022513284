:root {
  --textColor: #000000;
}

.img-logo {
  @media (max-width: 600px) {
    max-width: 250px;
    max-height: 120px;
    width: auto;
    height: auto;
  }

  @media (min-width: 601px) {
    width: 400px;
  }
}

.txt-label {
  color: var(--textColor);
  font-weight: 600;
  font-size: 16px;
  text-align: start;
}

.txt-validation {
  color: #eea25d;
  font-weight: 600;
  font-size: 13px;
  margin: -20px 0 0 0;
}

.txt-label-title {
  color: white;
  font-weight: 600;
  font-size: 28px;
}

.txt-welcomeMessage {
  margin-left: 10vw;
  margin-right: 10vw;
}

.txt-welcome {
  color: white;
  font-weight: 600;
  font-size: 12vw;
}

.button-custom {
  .button {
    padding: 100px;
  }
}

.checkbox-style {
  color: white !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: bold !important;
}

.form-style {
  color: white !important;
  opacity: 0.6;
  font-family: "Open Sans", sans-serif !important;
  font-weight: bold !important;
}

.select-stuff {
  border-color: white !important;
  color: white;
  fill: white;
  font-family: "Open Sans", sans-serif !important;
}

MuiSelect-select {
  border-color: white;
}

.MuiInputBase-input {
  color: wheat;
}
MuiSelect-icon {
  fill: white;
  color: white;
}

.datePicker {
  // color: white;
  input {
    color: var(--textColor);
    width: 100%;
    border-radius: 10px;
    border-color: var(--textColor);
    border-style: solid;
    border-width: 1px;
    background-color: transparent;
    margin-left: 0;
    height: 40px;
    font-size: 1rem;
    font-family: "Open Sans", sans-serif;
  }
}

.muiinputstuff {
  input {
    color: white;
    border-color: white !important;
  }
  MuiInputBase-root {
    color: white;
  }
  color: white;
}

.img-flecha-inv {
  transform: rotate(180deg);
}

.fieldset-class-field-dark {
  position: relative;
  border: 1px solid rgba(255, 255, 255, 0.5);
  border-radius: 10px;
  height: 30px;
}

.fieldset-class-field:focus-within {
  height: 40px;
}

.fieldset-label-class-field-dark .fieldset-input-class-field-dark {
  background: transparent;
  padding: 5px;
  outline: none;
  color: rgba(255, 255, 255, 0.5);
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.fieldset-label-class-field-dark {
  color: rgba(255, 255, 255, 0.5);
  position: absolute;
  pointer-events: none;
  top: 50%;
  left: 50%;
  width: max-content;
  transform: translate(-50%, -50%);
  transition: 0.1s ease-in all;
}

.fieldset-input-class-field-dark {
  font-family: "Open Sans", sans-serif;
  outline: none;
  background: transparent;
  width: 100%;
  color: white;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  font-size: 14px;
  font-weight: 600;
  transform: translate(-50%, -60%);
}

.fieldset-input-class-field-dark:focus {
  outline: none;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  background: transparent;
  width: 100%;
  color: white;
  transform: translate(-50%, -60%);
  font-size: 14px;
  font-weight: 600;
}

.fieldset-input-class-field-dark:not(:placeholder-shown) {
  outline: none;
  text-align: center;
  position: absolute;
  top: 50%;
  left: 50%;
  background: transparent;
  width: 100%;
  color: white;
  transform: translate(-50%, -60%);
  font-size: 14px;
  font-weight: 600;
}

.fieldset-input-class-field-dark:focus ~ .fieldset-class-field-dark {
  height: 50px;
}

.fieldset-input-class-field-dark:focus ~ .fieldset-label-class-field-dark {
  position: initial;
  font-size: small;
  color: white;
  margin-left: 5px;
  transform: translate(0%, 0%) scale(1);
  width: fit-content;
}

.fieldset-input-class-field-dark:not(:placeholder-shown)
  ~ .fieldset-label-class-field-dark {
  position: initial;
  font-size: small;
  color: rgba(255, 255, 255, 0.5);
  margin-left: 5px;
  transform: translate(0%, 0%) scale(1);
  width: fit-content;
}

.fieldset-class-field {
  position: relative;
  border: 1px solid rgba(0, 0, 0, 0.5);
  border-radius: 10px;
  height: 30px;
}

.fieldset-label-class-field .fieldset-input-class-field {
  background: transparent;
  padding: 5px 5px 5px 5px;
  outline: none;
  color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  border: none;
  text-align: center;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */

  transform: translate(-50%, -50%);
}

.fieldset-label-class-field {
  color: rgba(0, 0, 0, 0.5);
  position: absolute;
  pointer-events: none;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  width: max-content;
  transform: translate(-50%, -50%);
  transition: 0.1s ease-in all;
}

.fieldset-input-class-field {
  font-family: "Open Sans", sans-serif;
  outline: none;
  background: transparent;
  width: 100%;
  color: black;
  text-align: center;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  font-size: 14px;
  font-weight: 600;
  transform: translate(-50%, -60%);
}

.fieldset-input-class-field:focus {
  outline: none;
  text-align: center;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  background: transparent;
  width: 100%;
  color: black;
  transform: translate(-50%, -60%);
  font-size: 14px;
  font-weight: 600;
}

.fieldset-input-class-field:not(:placeholder-shown) {
  outline: none;
  text-align: center;
  position: absolute;
  top: 50%; /* position the top  edge of the element at the middle of the parent */
  left: 50%; /* position the left edge of the element at the middle of the parent */
  background: transparent;
  width: 100%;
  color: black;
  transform: translate(-50%, -60%);
  font-size: 14px;
  font-weight: 600;
}

.fieldset-input-class-field:focus ~ .fieldset-class-field {
  height: 50px;
}

.fieldset-input-class-field:focus ~ .fieldset-label-class-field {
  position: initial;
  font-size: small;
  color: black;
  margin-left: 5px;
  transform: translate(0%, 0%) scale(1);
  width: fit-content;
}

.fieldset-input-class-field:not(:placeholder-shown)
  ~ .fieldset-label-class-field {
  position: initial;
  font-size: small;
  color: rgba(0, 0, 0, 0.5);
  margin-left: 5px;
  transform: translate(0%, 0%) scale(1);
  width: fit-content;
}

@media (max-width: 480px) {
  .Toastify__toast-container--bottom-left,
  .Toastify__toast-container--bottom-center,
  .Toastify__toast-container--bottom-right {
    bottom: 10% !important;
    transform: translateX(0);
  }
}
@media (max-width: 480px) {
  .Toastify__toast-container {
    width: 80vw !important;
    position: fixed !important;
    margin-left: 10vw !important;
    z-index: 999999 !important;
    bottom: 10% !important;
  }
}

/* Override the background color of the menu list */
.MuiPopover-paper {
  background-color: #d9d9d9 !important; /* Change the background color as needed */
  // add border
  border: 1px solid white;
  border-radius: 10px !important;
}

.MuiPopover-paper li {
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}

// do the same of the past two with the autocomplete elements
.MuiAutocomplete-paper {
  background-color: #d9d9d9 !important; /* Change the background color as needed */
  // add border
  border: 1px solid white;
  border-radius: 10px !important;
}

.MuiAutocomplete-paper li {
  font-size: 14px;
  font-weight: 600;
  font-family: "Open Sans", sans-serif;
}

.MuiTypography-root {
  font-family: "Open Sans", sans-serif !important;
}

// apply open sans to everythin
* {
  font-family: "Open Sans", sans-serif;
}
