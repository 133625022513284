.container-destiny {
  margin: 0em 2em 0em 2em;
  color: white;
  .container-inputs {
    margin-top: 5em;
    margin-right: 3em;
  }
  .title-picker {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 28px;

    text-align: center;

    color: #ffffff;
  }
  .plan {
    margin-top: 3em;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 28px;
    /* or 156% */

    text-align: center;
  }
  .plan-square {
    margin-left: 3em;
    p {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 28px;
    }
  }
}
//     .react-date-picker react-date-picker--closed react-date-picker--enabled{
// width:100%;
//     }
