.enterdata-style {
  margin-top: 10%;
  color: white;
  padding: 2%;
  p {
    font-size: 24px;
  }
  .btn-enter {
    margin-top: 5em;
  }
}
