.hfMaster {
  --fontColorPrimary: "#ffffff";
}

.img-logo {
  @media (max-width: 600px) {
    max-width: 250px;
    max-height: 120px;
    width: auto;
    height: auto;
  }

  @media (min-width: 601px) {
    width: 400px;
  }
}

.txt-label2 {
  color: var(--fontColorPrimary);
  font-weight: 300;
  font-size: 20px;
}

@media (min-width: 480px) {
  .show-message {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}
@media (max-width: 480px) {
  .show-message {
    margin-top: 10vh;
    margin-bottom: 50px;
    margin-left: 40px;
    margin-right: 40px;
  }
}

.txt-validation-hf {
  color: #eea25d;
  font-weight: 500;
  font-size: 14px;
}

.txt-label-title-hf {
  color: var(--fontColorPrimary);
  font-weight: 600;
  font-size: 26px;
}

.txt-welcomeMessage-hf {
  margin-left: 10vw;
  margin-right: 10vw;
}

.txt-welcome-hf {
  color: var(--fontColorPrimary);
  font-weight: 600;
  font-size: 12vw;
}

.button-custom {
  .button {
    padding: 100px;
  }
}

.checkbox-style {
  color: var(--fontColorPrimary) !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: bold !important;
}

.form-style {
  color: white !important;
  font-family: "Open Sans", sans-serif !important;
  font-weight: bold !important;
}

.select-stuff {
  border-color: white !important;
  color: white;
  fill: white;
}

MuiSelect-select {
  border-color: white;
}

.MuiInputBase-input {
  color: wheat;
}
MuiSelect-icon {
  fill: white;
  color: white;
}

// .datePicker {
//   // color: white;
//   input {
//     color: white;
//     width: 100%;
//     border-radius: 10px;
//     border-color: white;
//     border-style: solid;
//     border-width: 1px;
//     background-color: transparent;
//     margin-left: 0;
//     height: 40px;
//     font-size: 1rem;
//     font-family: "Roboto", "Helvetica", "Arial", sans-serif;
//   }
// }

.muiinputstuff {
  input {
    color: white;
    border-color: white !important;
  }
  MuiInputBase-root {
    color: white;
  }
  color: white;
}

.img-flecha-inv {
  transform: rotate(180deg);
}
