.txt-title {
  margin-top: 3em;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;

  /* or 111% */

  text-align: center;

  color: #ffffff;
}

.btn-less {
  margin-top: 2%;
  line-height: 0px;
  text-align: -webkit-center;
  p {
    width: 244px;

    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    line-height: 14px;
    /* or 100% */

    text-align: center;

    color: rgba(255, 255, 255, 0.5);
  }
}
